import React from 'react';
import styled from '@emotion/styled';

import BranchesListFull from '@components/branches/BranchesListFull';
import { BranchesDataProps } from '@graphql/queries/BranchesQuery';

const StyledBranchesListFull = styled(BranchesListFull)``;

interface Props extends BranchesDataProps {
  langcode: string;
  urls: Array<string>;
}

const ProductsBranches = (props: Props) => {
  return (
    <StyledBranchesListFull
      allBranches={props.allBranches}
      branchSettigns={props.branchSettigns}
      langcode={props.langcode}
      urls={props.urls}
    />
  );
};

export default ProductsBranches;
