import React from 'react';
import styled from '@emotion/styled';
import { respondFrom, breakpoints, css } from '@styles';

import BranchesList from '@components/branches/BranchesList';
import ButtonLink from '@components/common/ButtonLink';
import { navigate } from '@utils';
import { BranchesDataProps } from '@graphql/queries/BranchesQuery';
import * as utils from '@utils';

const BranchesWrapper = styled.div`
  padding-top: 30px;
  position: relative;

  ${respondFrom(
    breakpoints.lg,
    css`
      margin-bottom: 150px;
    `
  )}

  > h2,
  > p {
    ${respondFrom(
      breakpoints.lg,
      css`
        text-align: center;
        max-width: 810px;
        margin-left: auto;
        margin-right: auto;
      `
    )}
  }

  > h2 {
    line-height: 1.3;
  }
  > p {
    ${respondFrom(
      breakpoints.lg,
      css`
        margin-bottom: 60px;
      `
    )}
  }
`;

interface Props extends BranchesDataProps {
  className?: string;
  langcode: string;
  urls: Array<string>;
}

interface TileProps {
  paragraph: string;
  buttonLabel: string;
  langcode: string;
  urls: Array<string>;
}

const BranchesListFull = (props: Props) => {
  return (
    <BranchesWrapper className={props.className || ''} id="section-list">
      <h2>{utils.SafeHtml(props.branchSettigns[props.langcode].sectionTitle)}</h2>
      <p></p>

      <BranchesList
        branches={props.allBranches[props.langcode]}
        // onlyOnline={true}
        smallInfoTile={
          <SmallInfoTile
            paragraph={props.branchSettigns[props.langcode].tileSmallParagraph}
            buttonLabel={props.branchSettigns[props.langcode].tileSmallButtonLabel}
            langcode={props.langcode}
            urls={props.urls}
          />
        }
        bigInfoTile={
          <BigInfoTile
            paragraph={props.branchSettigns[props.langcode].tileLargeParagraph}
            buttonLabel={props.branchSettigns[props.langcode].tileLargeButtonLabel}
            langcode={props.langcode}
            urls={props.urls}
          />
        }
        langcode={props.langcode}
        urls={props.urls}
        noDate
      />
    </BranchesWrapper>
  );
};

const SmallInfoTile = ({ paragraph, buttonLabel, langcode, urls }: TileProps) => {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleClick = (urlIndex: number) => {
        const targetUrl = utils.langLink(langcode, urls[urlIndex]);
        if (window.location.pathname === targetUrl) {
            scrollToTop();
            return;
        }
        navigate(targetUrl);
    }

    return (
        <div onClick={() => handleClick(0)}>
            {utils.SafeHtml(paragraph)}
            <ButtonLink
                onClick={() => handleClick(0)}
                to={utils.langLink(langcode, urls[0])}
                color="text"
                icon="arrow-right"
                propagateClickEvent={true}
            >
                {utils.SafeHtml(buttonLabel)}
            </ButtonLink>
        </div>
    );
};


const BigInfoTile = ({paragraph, buttonLabel, langcode, urls}: TileProps) => (
    <div onClick={() => navigate(utils.langLink(langcode, urls[18]))}>
        {utils.SafeHtml(paragraph)}
        <ButtonLink to={utils.langLink(langcode, urls[18])} color="text" icon="arrow-right">
      {utils.SafeHtml(buttonLabel)}
    </ButtonLink>
  </div>
);

export default BranchesListFull;
