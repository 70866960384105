import React from 'react';

import Page from '@components/layout/Page';
import BranchesContainer from '@containers/branches';
import useBranchesQuery from '@graphql/queries/BranchesQuery';
import { PageContext } from '@content/types/pageContext';
import MetaPage from '@components/common/MetaPage';
import DataLayer from '@components/layout/DataLayer';
import { LocationDependent } from '@content/types/general';

interface BranchesPageProps extends LocationDependent {
  pageContext: PageContext;
}

export default (props: BranchesPageProps) => {
  return (
    <Page>
      <MetaPage
        title={useBranchesQuery().branchSettigns[props.pageContext.langcode].seo.title}
        description={useBranchesQuery().branchSettigns[props.pageContext.langcode].seo.description}
        image={useBranchesQuery().branchSettigns[props.pageContext.langcode].seo.image}
      />
      <DataLayer location={props.location} />
      <BranchesContainer
        {...useBranchesQuery()}
        pageContext={props.pageContext}
        langcode={props.pageContext.langcode}
        urls={props.pageContext.allUrls}
      />
    </Page>
  );
};
