import React from 'react';

import Page from '@components/layout/Page';
import Container from '@components/layout/Container';
import BranchesHero from '@components/branches/BranchesHero';
import BranchesBranches from '@components/branches/BranchesBranches';
import { BranchesDataProps } from '@graphql/queries/BranchesQuery';
import { PageContext } from '@content/types/pageContext';

export interface Props {}
export interface BranchesProps extends BranchesDataProps {
  pageContext: PageContext;
  langcode: string;
  urls: Array<string>;
}

export default (props: BranchesProps) => {
  return (
    <Page>
      <BranchesHero
        branchSettigns={props.branchSettigns[props.langcode]}
        langcode={props.langcode}
        urls={props.urls}
      />
      <Container>
        <BranchesBranches
          allBranches={props.allBranches}
          branchSettigns={props.branchSettigns} /*pageContext={props.pageContext}*/
          langcode={props.langcode}
          urls={props.urls}
        />
      </Container>
    </Page>
  );
};
