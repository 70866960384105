import React from 'react';
import styled from '@emotion/styled';
import Image from 'gatsby-image';

import { colors, dimensions, animation, respondFrom, breakpoints, css, mixins } from '@styles';
import { Branch } from '@content/types/branch';
import Badge from '@components/common/Badge';
import { Link } from '@utils';
import * as utils from '@utils';
import ButtonLink from '@components/common/ButtonLink';

const itemMaxWidth = breakpoints.md / 2;
const itemPaddingMobile = 10;

interface StylesProps {
  shouldlink?: number;
}

const StyledBranchesItem = styled(Link)<StylesProps>`
  position: relative;
  overflow: hidden;
  text-decoration: none !important;

  width: 100%; /* 1 in row */
  max-width: ${itemMaxWidth}px;
  padding: ${itemPaddingMobile}px 0;

  pointer-events: ${props => (props.shouldlink ? 'initial' : 'none')};
  filter: ${props => (props.shouldlink ? 'none' : 'grayscale(100%)')};

  ${respondFrom(
    breakpoints.md,
    css`
      flex: 1 0 40%; /* +max-width -> force 2 in row */
      width: auto;
      padding: ${itemPaddingMobile}px;
    `
  )}

  ${respondFrom(
    breakpoints.xl,
    css`
      flex: 0 0 25%; /* 4 in row */
      padding: ${dimensions.boxesSpacing / 2}px;
    `
  )}

  &.space-large {
    ${respondFrom(
      breakpoints.xl,
      css`
        margin-bottom: 30px;
      `
    )}
  }

  &:hover,
  &:focus,
  &:active {
    img {
      transform: scale(1.05);
    }
    p:before {
      transform: scaleX(2);
    }
  }
`;

const StyledBranchesItemInner = styled.div`
  position: relative;
  overflow: hidden;
`;

const StyledBranchesItemContent = styled.div`
  background-color: ${colors.ui.whisper};
  padding: 20px 15px;
  position: relative;
  margin: 0;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-height: 80px;

  h3 {
    font-weight: 500;
    color: ${colors.green.dark};
    text-transform: uppercase;
    position: relative;
    font-size: ${dimensions.fontSize.regular}px;
    line-height: 1.19;

    &.no-date {
      margin: 10px 0;
    }
  }

  p {
    font-size: ${dimensions.fontSize.regular}px;
    line-height: 1.19;
    color: ${colors.text.medium};
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 1px;
      width: 20px;
      background-color: ${colors.text.medium};
      top: -0.5em;
      left: 50%;
      margin-left: -10px;

      ${mixins.transitionDefault}
      transition-duration: ${animation.slow}ms !important;
    }
  }
`;

const StyledImage = styled(Image)`
  width: 100% !important;
  max-width: 100%;
  height: auto;
  overflow: hidden;

  img {
    ${mixins.transitionDefault}
    transition-duration: ${animation.subtle}ms !important;
    transition-timing-function: ease-out;
    transition-property: transform !important;
    transform-origin: center;
  }
`;

const StyledBranchesItemIcon = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 106px;
  height: 106px;
  transform: translate(-50%, -50%);
  background-color: ${colors.ui.whisper};
  border-radius: 50%;
  z-index: 1;

  svg, img {
    margin: 10px auto 0 auto;
  }
`;

interface BranchesItemSmallProps extends Branch {
  noDate?: boolean;
  slugOutsideBranch?: boolean;
  space?: string;
  imageAlt?: string;
  langcode: string;
  urls: Array<string>;
}

export default (props: BranchesItemSmallProps) => (
  <StyledBranchesItem
    to={utils.langLink(
      props.langcode,
      props.slugOutsideBranch ? props.slug : `${props.urls[3]}/${props.slug || ''}`
    )}
    shouldlink={props.slugOutsideBranch ? 1 : props.sections && props.sections.length ? 1 : 0}
    className={`space-${props.space}`}
  >
    <StyledBranchesItemInner>
      {props.image && <StyledImage {...props.image.childImageSharp} alt={props.imageAlt} />}
      {props.hasOnline ? <Badge /> : ''}

      <StyledBranchesItemContent>
        {
          props.icon && (
          <StyledBranchesItemIcon>
            <img src={props.icon} />
          </StyledBranchesItemIcon>
          )
        }
        <div style={{ zIndex: 2 }}>
          <h3 className={props.noDate ? 'no-date' : ''}>{props.name}</h3>
          {!props.noDate && props.fromDate ? <p>od {props.fromDate}</p> : ''}
        </div>
      </StyledBranchesItemContent>
    </StyledBranchesItemInner>
  </StyledBranchesItem>
);

/* ---- */
const StyledHomeBranchesItemSmallTextBreak = styled.div`
  width: 100%;
  max-width: ${itemMaxWidth}px;
  padding: 30px ${dimensions.boxesSpacing / 2}px;

  ${respondFrom(
    breakpoints.md,
    css`
      flex: 1 0 40%; /* +max-width -> force 2 in row */
      width: auto;
      padding: ${itemPaddingMobile}px;
    `
  )}
  ${respondFrom(
    breakpoints.xl,
    css`
      flex: 0 0 25%; /* 4 in row */
      padding: ${dimensions.boxesSpacing / 2}px;
    `
  )}

  > div {
    padding: 20px;
  }
`;

interface HomeBranchesItemSmallTextBreakProps {
  paragraph: string;
  buttonLabel: string;
  langcode: string;
  urls: Array<string>;
}

export const HomeBranchesItemSmallTextBreak = (props: HomeBranchesItemSmallTextBreakProps) => (
  <StyledHomeBranchesItemSmallTextBreak>
    <div>
      {utils.SafeHtml(props.paragraph)}
      <ButtonLink to={utils.langLink(props.langcode, props.urls[0])} color="text" icon="arrow-right">
        {utils.SafeHtml(props.buttonLabel)}
      </ButtonLink>
    </div>
  </StyledHomeBranchesItemSmallTextBreak>
);
