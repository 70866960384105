import React from 'react';
import styled from '@emotion/styled';
import { colors, respondFrom, respondTo, breakpoints, css, dimensions, fluidRange } from '@styles';
import { Branch } from '@content/types/branch';
import HomeBranchesItemSmall from '@components/home/HomeBranchesItemSmall';

const BranchesListWrapper = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row wrap;
`;

const SmallInfoTile = styled.div`
  /* flex: 0 0 25%;
  margin: 0;
  padding: 8px; */

  width: 100%; /* 1 in row */
  max-width: ${breakpoints.md / 2}px;
  padding: 10px 0;

  a {
    padding-bottom: 0 !important;
  }

  ${respondFrom(
    breakpoints.md,
    css`
      flex: 1 0 40%; /* +max-width -> force 2 in row */
      width: auto;
      padding: 10px;
    `
  )}

  ${respondFrom(
    breakpoints.xl,
    css`
      flex: 0 0 25%; /* 4 in row */
      margin: 0 !important;
      padding: ${dimensions.boxesSpacing / 2}px;
    `
  )}

  > div {
    ${respondTo(
      breakpoints.xl,
      css`
        padding: 40px 30px;
      `
    )}

    ${fluidRange('paddingTop', '30px', '40px', breakpoints.xl, breakpoints.xxl)}
    ${fluidRange('paddingRight', '30px', '50px', breakpoints.xl, breakpoints.xxl)}
    ${fluidRange('paddingBottom', '30px', '40px', breakpoints.xl, breakpoints.xxl)}
    ${fluidRange('paddingLeft', '30px', '45px', breakpoints.xl, breakpoints.xxl)}
    background-color: ${colors.grayNurse};
    min-height: 290px;

    h3,
    p {
      text-align: left;
    }
  }
`;

const BigInfoTile = styled.div`
  width: 100%; /* 1 in row */
  max-width: 768px;
  padding: 10px 0;

  ${respondFrom(
    breakpoints.xl,
    css`
      width: 50%;
      margin: 0 auto;
    `
  )}

  > div {
    ${respondTo(
      breakpoints.xl,
      css`
        padding: 10px 0;
      `
    )}

    ${fluidRange('paddingTop', '30px', '40px', breakpoints.xl, breakpoints.xxl)}
    ${fluidRange('paddingRight', '30px', '50px', breakpoints.xl, breakpoints.xxl)}
    ${fluidRange('paddingBottom', '30px', '40px', breakpoints.xl, breakpoints.xxl)}
    ${fluidRange('paddingLeft', '30px', '45px', breakpoints.xl, breakpoints.xxl)}

    h3,
    p {
      text-align: left;
    }
  }
`;

const InfoTitle = styled.div`
  width: 100%; /* 1 in row */
  max-width: ${breakpoints.md / 2}px;
  padding: 10px 0;

  ${respondFrom(
    breakpoints.md,
    css`
      flex: 1 0 40%; /* +max-width -> force 2 in row */
      width: auto;
      padding: 10px;
    `
  )}

  ${respondFrom(
    breakpoints.xl,
    css`
      flex: 0 0 25%; /* 4 in row */
      padding: ${dimensions.boxesSpacing / 2}px;
    `
  )}

  &:not(.transparent) {
    > div {
      background-color: ${colors.grayNurse};
    }
  }

  > div {
    padding: 20px;
    min-height: 290px;

    h3 {
      font-size: 26px;
    }

    h3,
    p {
      text-align: left;
    }
  }
`;

interface BranchesListProps {
  branches: Branch[];
  onlyOnline?: boolean;
  smallInfoTile?: string | React.ReactElement;
  bigInfoTile?: string | React.ReactElement;
  noDate?: boolean;
  langcode: string;
  urls: Array<string>;
}

const BranchesList = ({
  branches,
  onlyOnline,
  smallInfoTile,
  bigInfoTile,
  noDate,
  langcode,
  urls,
}: BranchesListProps) => {
  let branchesUpdated: Branch[] = [];

  if (onlyOnline) {
    branches.forEach(branch => {
      if (branch.hasOnline) {
        branchesUpdated.push(branch);
      }
    });
  } else {
    branchesUpdated = branches;
  }

  return (
    <BranchesListWrapper>
      {branchesUpdated.map((branch, index) => (
        <HomeBranchesItemSmall
          {...branch}
          key={index}
          noDate={noDate}
          langcode={langcode}
          urls={urls}
        />
      ))}

      <InfoTitle className="hoverable">{smallInfoTile}</InfoTitle>
      <InfoTitle className="hoverable transparent">{bigInfoTile}</InfoTitle>
    </BranchesListWrapper>
  );
};

export default BranchesList;
