import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import actions from '@store/actions';
import BranchesComponent, { BranchesProps } from '@components/branches';
import { RootState } from '@store/types';

const mapStateToProps = (state: RootState) => ({
  configurator: state.configurator,
  content: state.content,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  configuratorActions: bindActionCreators(actions.configurator, dispatch),
  contentActions: bindActionCreators(actions.content, dispatch),
});

interface DBranchesPropsProps extends BranchesProps {}
const DNews: React.FC<DBranchesPropsProps> = (props: DBranchesPropsProps) => (
  <BranchesComponent {...props} />
);

const NewsConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(DNews);

export default NewsConnected;
