import React from 'react';
import Hero from '@components/common/Hero';
import { BranchSettings } from '@content/types/branch';
import * as utils from '@utils';

interface CennikHeroProps {
  branchSettigns: BranchSettings;
  langcode: string;
  urls: Array<string>;
}

export default (props: CennikHeroProps) => (
  <Hero
    size="medium"
    image={
      props.branchSettigns.hero.heroImage
        ? props.branchSettigns.hero.heroImage.childImageSharp
        : undefined
    }
    imageMobile={
      props.branchSettigns.hero.heroImageMobile
        ? props.branchSettigns.hero.heroImageMobile.childImageSharp
        : undefined
    }
    imageAlt={props.branchSettigns.hero.heroImageAlt}
    title={props.branchSettigns.hero.heroTitle}
    paragraph={props.branchSettigns.hero.heroParagraph}
    buttonText={props.branchSettigns.hero.heroButtonLabel}
    buttonRoute={utils.langLink(props.langcode, `${props.urls[3]}#section-list`)}
    theme="dark"
  ></Hero>
);
